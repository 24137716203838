import React from "react";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import CssBaseline from "@mui/material/CssBaseline";
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import "./App.css";

//components
import Edit from "./components/Edit";
import Login from "./components/Login";
import Main from "./components/Main";
import PrivateRoute from "./components/PrivateRoute";
import Result from "./components/Results";
import Settings from "./components/Settings";
//context
import { AuthProvider } from "./context/AuthContext";
import { ConfigProvider } from "./context/ConfigContext";
//config
import "./i18n/config";

library.add(fab);

function App() {
  return (
    <ConfigProvider>
      <ConfirmProvider>
        <CssBaseline />
        <SnackbarProvider>
          <AuthProvider>
            <Router>
              <Switch>
                <PrivateRoute exact path="/" component={Main} />
                <PrivateRoute path="/results/:id" component={Result} />
                <PrivateRoute path="/edit/:id" component={Edit} />
                <Route exact path="/login" component={Login} />
                <PrivateRoute exact path="/settings" component={Settings} />
                <Redirect to="/" />
              </Switch>
            </Router>
          </AuthProvider>
        </SnackbarProvider>
      </ConfirmProvider>
    </ConfigProvider>
  );
}

export default App;
