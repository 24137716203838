import React from "react";

type CategoryItem = {
  name: string;
  link: string;
};

type Category = {
  name: string;
  link: string;
  subCategories: CategoryItem[];
};

type ContentsType = {
  sections: Category[];
};

const Contents: React.FC<ContentsType> = ({ sections }) => {
  return (
    <ol>
      {sections.map((category: Category, index: number) => (
        <li key={index}>
          <a href={`#${category.link}`}>{category.name}</a>
          <ol>
            {category.subCategories.map((subCategory: CategoryItem, index) => (
              <li key={index}>
                <a href={`#${subCategory.link}`}>{subCategory.name}</a>
              </li>
            ))}
          </ol>
        </li>
      ))}
    </ol>
  );
};

export default Contents;
