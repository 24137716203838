import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { TextField } from "@stayunique/stayunique-ui";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

import fetcher from "../utils/fetcher";
import Navigation from "./Layout/Navigation";

const SearchBox = styled(TextField)`
  width: 100%;
  & > div {
    border-radius: 50px;
  }
`;

const Results = styled.div`
  margin-top: 20px;
  height: 600px;
  overflow: auto;
`;

interface Properties {
  propertyName: string;
  propertyId: string;
}

const Home: React.FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [results, setResults] = useState<Properties[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      if (search.length > 0) {
        try {
          const results = await fetcher(
            `/wiki/search?${queryString.stringify({ query: search })}`
          );
          setResults(results.data);
        } catch (e) {
          enqueueSnackbar(t("misc.error"), {
            variant: "error",
            preventDuplicate: true,
          });
        }
      } else {
        setResults([]);
      }

      setLoading(false);
    }

    fetchData();
  }, [search, enqueueSnackbar, t]);

  const change = (value: any) => {
    setValue(value);
    debouncedSearch(value);
  };

  const debouncedSearch = React.useMemo(
    () =>
      debounce((val) => {
        setSearch(val);
      }, 500),
    [setSearch]
  );

  return (
    <>
      <Navigation open={false} />

      <Box sx={{ mt: 10, ml: 2, mr: 2 }}>
        <div>
          <SearchBox
            id="search"
            placeholder={t("home.search")}
            autoComplete="off"
            variant="outlined"
            value={value}
            onChange={change}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {loading && <CircularProgress color="primary" size={20} />}{" "}
                </InputAdornment>
              ),
            }}
          />
          <Results>
            <List>
              {results.map((result: Properties, index: number) => (
                <List key={index}>
                  <ListItem
                    button
                    component={RouterLink}
                    to={`/results/${result.propertyId}`}
                  >
                    <ListItemText primary={`${result.propertyName}`} />
                  </ListItem>
                </List>
              ))}
            </List>
          </Results>
        </div>
      </Box>
    </>
  );
};

export default Home;
