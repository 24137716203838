import React, { useState } from "react";

import ArrowBack from "@mui/icons-material/ArrowBack";
import { Box, Stack } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const Settings: React.FC = () => {
  const { i18n, t } = useTranslation();
  const [currentLang, setCurrentLang] = useState<string>(i18n.language);

  const history = useHistory();

  const changeLang = async (lang: string) => {
    await i18n.changeLanguage(lang);
    setCurrentLang(lang);
    localStorage.setItem("lang", lang);
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            onClick={() => {
              history.push("/");
            }}
            color="inherit"
            aria-label={t("misc.back")}
            size="large"
            sx={{ mr: 2 }}
          >
            <ArrowBack />
          </IconButton>

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {t("settings.title")}
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ m: 2 }}>
        <Stack spacing={2} direction="row">
          <Button
            onClick={() => changeLang("en")}
            variant={currentLang === "en" ? "contained" : "outlined"}
          >
            {t("languages.english")}
          </Button>
          <Button
            onClick={() => changeLang("es")}
            variant={currentLang === "es" ? "contained" : "outlined"}
          >
            {t("languages.spanish")}
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default Settings;
