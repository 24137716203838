import { merge } from "lodash";

export default async function fetcher(
  url: string,
  options: any = {}
): Promise<any> {
  const defaultOptions = {
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  };

  const fetchOptions: any = merge(defaultOptions, options);
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/${url}`,
      fetchOptions
    );

    const result = await res.json();

    if (res.status !== 200) {
      throw result;
    }

    return result;
  } catch (e) {
    throw e;
  }
}
