import React, { useState, useEffect } from "react";

import { ListItem, ListItemText } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

//utils
import fetcher from "../utils/fetcher";
import Dialog from "./common/Dialog";

interface IMissing {
  itemId: string;
  itemLocationId: string;
  itemName: string;
  itemTypeId: string;
}

const MissingDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  setDisableMissingButton: (value: boolean) => void;
}> = ({ open, onClose, setDisableMissingButton }) => {
  const { id } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [missingItems, setMissingItems] = useState<IMissing[]>([]);

  useEffect(() => {
    missingItems.length > 0
      ? setDisableMissingButton(false)
      : setDisableMissingButton(true);
  }, [missingItems, setDisableMissingButton]);

  useEffect(() => {
    (async function fetchMissingItems() {
      try {
        const missingItemsList = await fetcher(`/wiki/missing/${id}`);
        setMissingItems(missingItemsList.data);
      } catch (e: any) {
        enqueueSnackbar(e.message, {
          variant: "error",
          preventDuplicate: true,
        });
      }
    })();
  }, [enqueueSnackbar, id]);

  return (
    <Dialog onClose={onClose} open={open} title={t("edit.missingTitle")}>
      {missingItems.map((item, index: number) => (
        <ListItem key={index}>
          <ListItemText primary={item.itemName} />
        </ListItem>
      ))}
    </Dialog>
  );
};

export default MissingDialog;
