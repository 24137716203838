import React from "react";

import { Redirect, Route } from "react-router-dom";

import { useAuth } from "../../context/AuthContext";

interface PrivateRouteProps {
  exact?: boolean;
  path: string;
  component: any;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  exact,
  ...rest
}) => {
  const { isAuthenticated } = useAuth();

  return (
    <Route
      {...rest}
      render={(props: any) =>
        isAuthenticated ? (
          <Component exact={exact} {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
