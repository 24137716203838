import React, { useEffect, useMemo, useState } from "react";

import BallotIcon from "@mui/icons-material/Ballot";
import EditIcon from "@mui/icons-material/Edit";
import { Paper } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Carousel, FileRow, Loader } from "@stayunique/stayunique-ui";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import fetcher from "../../utils/fetcher";
import Navigation from "../Layout/Navigation";
import Contents from "./Contents";
import SummaryTable from "./SummaryTable";

const drawerWidth = 400;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const PropertyItem = styled("div")`
  margin: 20px 0;
  border-radius: 8px;
  border: 2px solid #e9e9e9;
  padding: 10px;
`;

const convertToEntities = (categories: Category[]) => {
  const results = categories.map((category: Category) => {
    //filter out items where the item does NOT exist
    const items = category.items.filter(
      (item: Item) => item.itemExists === "1"
    );

    return {
      ...category,
      items: items,
    };
  });

  return results.filter((result: Category) => result.items.length > 0);
};

interface Category {
  typeId: number;
  typeName: string;
  items: Item[];
}

interface Item {
  itemId: number;
  itemName: string;
  itemExists: string;
  subItems: SubItem[];
  locationId: string;
}

interface SubItem {
  propertyItemId: string;
  itemId: string;
  locationId: string;
  propertyId: string;
  itemModel: string;
  itemInstructions: null | string;
  itemManual: string;
  itemVideo: string;
  itemComments: null | string;
  visibleTo: string;
  locationName: string;
  itemImages: string[];
}

interface Summary {
  sleeps: number;
  numBedrooms: number;
  numFullBathrooms: number;
  numHalfBathrooms: number;
  salesSleeps: number;
  numSingleBeds: number;
  numDoubleBeds: number;
  numSingleSofaBeds: number;
  numDoubleSofaBeds: number;
}

export default function ResponsiveDrawer(props: Props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { window } = props;

  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>() as { id: string };
  const { enqueueSnackbar } = useSnackbar();

  const [currentImages, setCurrentImages] = useState<string[]>([]);
  const [carouselOpen, setCarouselOpen] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [propertyName, setPropertyName] = useState<string>("");
  const [summary, setSummary] = useState<Summary>({
    sleeps: 0,
    numBedrooms: 0,
    numFullBathrooms: 0,
    numHalfBathrooms: 0,
    salesSleeps: 0,
    numSingleBeds: 0,
    numDoubleBeds: 0,
    numSingleSofaBeds: 0,
    numDoubleSofaBeds: 0,
  });

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      try {
        const results = await fetcher(`/wiki/view/${id}`);

        setCategories(convertToEntities(results.data.types));

        setPropertyName(results.data.propertyName);

        const summary = await fetcher(`/wiki/summary/${id}`);
        setSummary(summary);

        setLoading(false);
      } catch (e) {
        enqueueSnackbar(t("misc.error"), {
          variant: "error",
          preventDuplicate: true,
        });

        setLoading(false);
      }
    }

    fetchData();
  }, [id, enqueueSnackbar, t]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const openLightbox = (images: string[], index: number) => {
    setCarouselOpen(true);
    setSelectedIndex(index);
    setCurrentImages(images);
  };

  const closeLightbox = () => {
    setCarouselOpen(false);
    setSelectedIndex(0);
    setCurrentImages([]);
  };

  const toLink = (name: string) => name.replace(/\s/g, "_");

  const sectionsList = useMemo(() => {
    return categories.map((category: Category) => {
      const subCategories = category.items.map((item: Item) => ({
        name: item.itemName,
        link: toLink(item.itemName),
      }));

      return {
        name: category.typeName,
        link: toLink(category.typeName),
        subCategories: subCategories,
      };
    });
  }, [categories]);

  if (loading) {
    return <Loader />;
  }

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box>
      <CssBaseline />

      <Navigation
        open={false}
        showBackButton={true}
        title={propertyName}
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          mr: { sm: `${drawerWidth}px` },
        }}
        buttons={
          <>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: "none" } }}
            >
              <BallotIcon />
            </IconButton>

            <IconButton
              onClick={() => {
                history.push(`/edit/${id}`);
              }}
              aria-label={t("results.edit")}
              color="inherit"
            >
              <EditIcon />
            </IconButton>
          </>
        }
      />

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          anchor="right"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <Contents sections={sectionsList} />
        </Drawer>
        <Drawer
          anchor="right"
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          <Contents sections={sectionsList} />
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          mt: "60px",
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <SummaryTable
          rows={[
            { name: t("results.capacity"), value: summary.sleeps },
            { name: t("results.salesCapacity"), value: summary.salesSleeps },
            { name: t("results.bedrooms"), value: summary.numBedrooms },
            {
              name: t("results.fullBathRooms"),
              value: summary.numFullBathrooms,
            },
            {
              name: t("results.halfBathrooms"),
              value: summary.numHalfBathrooms,
            },
            { name: t("results.singleBeds"), value: summary.numSingleBeds },
            { name: t("results.doubleBeds"), value: summary.numDoubleBeds },
            {
              name: t("results.singleSofaBeds"),
              value: summary.numSingleSofaBeds,
            },
            {
              name: t("results.doubleSofaBeds"),
              value: summary.numDoubleSofaBeds,
            },
          ]}
        />
        {categories.map((category: Category, index: number) => (
          <Paper
            key={index}
            sx={{
              padding: "10px",
              margin: "20px 0",
              position: "relative",
              span: {
                position: "absolute",
                top: "-100px",
              },
            }}
          >
            <span id={`${toLink(category.typeName)}`} />
            <Typography variant="h6" color="primary" paragraph>
              {category.typeName}
            </Typography>

            {category.items.map((item: Item) => (
              <Box
                key={item.itemName}
                sx={{
                  position: "relative",
                  span: {
                    position: "absolute",
                    top: "-100px",
                  },
                }}
              >
                <span id={`${toLink(item.itemName)}`} />

                <Typography variant="body1" fontWeight={700}>
                  {item.itemName}
                </Typography>

                {item.subItems.map((subItem: SubItem, index: number) => (
                  <PropertyItem key={index}>
                    {subItem.locationName && (
                      <Box sx={{ mb: 1 }}>
                        <Typography variant="subtitle2">
                          {t("results.location")}
                        </Typography>
                        <Typography>{subItem.locationName}</Typography>
                      </Box>
                    )}

                    <Typography variant="subtitle2">
                      {t("results.images")}
                    </Typography>
                    <FileRow
                      key={`image${index}`}
                      items={subItem.itemImages}
                      selectedItem={selectedIndex}
                      width={150}
                      height={150}
                      onClick={(index: number) =>
                        openLightbox(subItem.itemImages, index)
                      }
                      color="black"
                      align="flex-start"
                      embedded={true}
                    />

                    {subItem.itemComments && (
                      <Box sx={{ mb: 1 }}>
                        <Typography>{subItem.itemComments}</Typography>
                      </Box>
                    )}
                  </PropertyItem>
                ))}
              </Box>
            ))}
          </Paper>
        ))}
        <Carousel
          allowsDelete={false}
          items={currentImages}
          open={carouselOpen}
          onClose={closeLightbox}
          index={selectedIndex}
        />
      </Box>
    </Box>
  );
}
