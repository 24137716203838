import React from "react";

import AccountCircle from "@mui/icons-material/AccountCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, SxProps } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// components
import { useAuth } from "../../context/AuthContext";
import { useConfig } from "../../context/ConfigContext";
import Logo from "./Logo";

//context

type NavigationType = {
  buttons?: React.ReactNode;
  toolbarSecondary?: React.ReactNode;
  open: boolean;
  showBackButton?: boolean;
  title?: string;
  sx?: SxProps;
};

const Navigation: React.FC<NavigationType> = ({
  buttons,
  toolbarSecondary,
  showBackButton,
  title,
  sx,
}) => {
  const { auth, logout } = useAuth();
  const history = useHistory();
  const { t } = useTranslation();

  const {
    config: { appName },
  } = useConfig();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openMenu = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const userLogout = async () => {
    await logout();

    history.replace("/login");
  };

  return (
    <AppBar position="fixed" sx={sx}>
      <Toolbar>
        {showBackButton ? (
          <IconButton
            sx={{ mr: 2 }}
            aria-label={t("misc.back")}
            onClick={() => history.push(`/`)}
            color="inherit"
            size="large"
          >
            <ArrowBackIcon />
          </IconButton>
        ) : (
          <Logo />
        )}

        <Typography
          variant="h6"
          component="div"
          sx={{
            flexGrow: 1,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {title ? title : appName}
        </Typography>

        <Box sx={{ flexGrow: 1 }} />

        {buttons}

        <IconButton
          aria-label={t("nav.userProfile")}
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleClick}
          color="inherit"
          size="large"
        >
          <AccountCircle />
        </IconButton>

        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={openMenu}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              history.push("/settings");
            }}
          >
            {t("nav.settings")}
          </MenuItem>
          <MenuItem onClick={userLogout}>
            {t("nav.logout", { user: auth.userName })}
          </MenuItem>
        </Menu>
      </Toolbar>
      {toolbarSecondary}
    </AppBar>
  );
};

export default Navigation;
