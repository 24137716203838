import React, { useState, useEffect } from "react";

import { DialogActions, Button, Typography } from "@mui/material";
import { Select } from "@stayunique/stayunique-ui";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

//utils
import fetcher from "../utils/fetcher";
import Dialog from "./common/Dialog";

interface IProperties {
  buildingId: string;
  buildingName: string;
}

type CloneDialogType = {
  open: boolean;
  onClose: any;
  onClone: any;
  propertyId: string;
};

export default function CloneDialog({
  open,
  onClose,
  onClone,
  propertyId,
}: CloneDialogType) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [properties, setProperties] = useState<IProperties[]>([]);
  const [clonePropertyId, setClonePropertyId] = useState<string>("");

  useEffect(() => {
    async function fetchData() {
      try {
        const properties = await fetcher(`/properties`);
        setProperties(properties.data);
      } catch (e) {
        enqueueSnackbar(t("misc.error"), {
          variant: "error",
          preventDuplicate: true,
        });
      }
    }

    fetchData();
  }, [enqueueSnackbar, t]);

  const clonePropertyData = async () => {
    try {
      await fetcher(`/wiki/clone/${propertyId}`, {
        method: "POST",
        body: JSON.stringify({ clone: clonePropertyId }),
      });

      onClone();
    } catch (e: any) {
      enqueueSnackbar(e.message, { variant: "error", preventDuplicate: true });
    }
  };

  const handleClone = async () => {
    await clonePropertyData();
    onClose();
  };

  return (
    <Dialog onClose={onClose} title={t("edit.cloneDialogTitle")} open={open}>
      <Typography>{t("edit.propertyToClone")}</Typography>
      <Select
        isClearable
        value={clonePropertyId}
        placeholder={t("edit.property")}
        onChange={(value: any) => setClonePropertyId(value)}
        options={properties.map((option: any) => ({
          value: option.propertyId,
          label: option.propertyName,
        }))}
      />
      <DialogActions>
        <Button autoFocus onClick={handleClone} color="primary">
          {t("edit.clone")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
