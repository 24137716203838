import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  DialogContent,
  IconButton,
  DialogTitle as MuiDialogTitle,
  Dialog as MuiDialog,
} from "@mui/material";

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const DialogTitle: React.FC<DialogTitleProps> = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <MuiDialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const Dialog: React.FC<{
  title: string;
  open: boolean;
  children?: React.ReactNode;
  onClose: () => void;
}> = ({ title, open, children, onClose }) => {
  return (
    <MuiDialog onClose={onClose} aria-labelledby={title} open={open}>
      <DialogTitle id="dialog-title" onClose={onClose}>
        {title}
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </MuiDialog>
  );
};

export default Dialog;
