import React, { useState } from "react";

import { Box } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { useAuth } from "../context/AuthContext";
import { useConfig } from "../context/ConfigContext";
import Logo from "./Layout/Logo";

type LoginType = {
  username: string;
  password: string;
};

const Login: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const {
    config: { appName },
  } = useConfig();

  const [user, setUser] = useState<LoginType>({ username: "", password: "" });
  const { isAuthenticated, login } = useAuth();

  if (isAuthenticated) {
    history.push("/");
  }

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();

    try {
      await login(user);
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: "error",
        preventDuplicate: true,
      });
    }
  };

  const handleChange = (event: any) => {
    setUser((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Logo />

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {appName}
          </Typography>
        </Toolbar>
      </AppBar>

      <Box
        component="form"
        sx={{ ml: 1, mr: 1 }}
        noValidate
        autoComplete="off"
        onSubmit={(event: any) => handleFormSubmit(event)}
      >
        <TextField
          required
          variant="standard"
          id="username"
          name="username"
          label={t("login.username")}
          margin="normal"
          value={user.username}
          onChange={handleChange}
        />
        <br />

        <TextField
          required
          variant="standard"
          id="password"
          name="password"
          label={t("login.password")}
          type="password"
          margin="normal"
          value={user.password}
          onChange={handleChange}
        />
        <br />
        <Button type="submit" variant="contained" color="primary">
          {t("login.login")}
        </Button>
      </Box>
    </>
  );
};

export default Login;
