import React, { useState, useEffect, useCallback } from "react";

import ArrowBack from "@mui/icons-material/ArrowBack";
import BallotIcon from "@mui/icons-material/Ballot";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopy from "@mui/icons-material/FileCopy";
import Save from "@mui/icons-material/Save";
import { Box } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Loader } from "@stayunique/stayunique-ui";
import arrayMutators from "final-form-arrays";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

//utils
import fetcher from "../utils/fetcher";
//fields
import CloneDialog from "./CloneDialog";
import TabPanel from "./common/TabPanel";
import Radio from "./fields/Radio";
import Select from "./fields/Select";
import TextField from "./fields/TextField";
import Uploader from "./fields/Uploader";
import MissingDialog from "./MissingDialog";

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Edit: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const { id } = useParams<{ id: string }>();
  const [property, setProperty] = useState<any | {}>({ types: [] });
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [missingDialogOpen, setMissingDialogOpen] = useState(false);
  const [cloneDialogOpen, setCloneDialogOpen] = useState(false);
  const [disableMissingButton, setDisableMissingButton] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const property = await fetcher(`/wiki/view/${id}`);

      setProperty(property.data);

      const rooms = await fetcher(`/wiki/rooms/${id}`);

      setRooms(rooms.data);

      setActiveStep(0);
    } catch (e) {
      enqueueSnackbar(t("misc.error"), {
        variant: "error",
        preventDuplicate: true,
      });
    }

    setLoading(false);
  }, [enqueueSnackbar, id, t]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  let submit: any;

  const handleSave = async () => {
    try {
      await submit();
      enqueueSnackbar(t("edit.saveSuccess"), {
        variant: "success",
        preventDuplicate: true,
      });
    } catch (e) {
      enqueueSnackbar(t("misc.error"), {
        variant: "error",
        preventDuplicate: true,
      });
    }
  };

  const save = async (values: any) => {
    setIsDisabled(true);

    try {
      await fetcher(`wiki/update/${id}`, {
        method: "POST",
        body: JSON.stringify(values),
      });
    } catch (e) {
      throw e;
    }

    setIsDisabled(false);
  };

  const changeTab = (event: any, newValue: any) => {
    setActiveStep(newValue);
  };

  const confirmDelete = (fields: any, index: any) => {
    confirm({
      description: t("edit.deleteItem"),
      confirmationButtonProps: { autoFocus: true },
    })
      .then(() => fields.remove(index))
      .catch((e) =>
        enqueueSnackbar(e.message, { variant: "error", preventDuplicate: true })
      );
  };

  const onClone = () => {
    setCloneDialogOpen(false);
    fetchData();
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            onClick={() => {
              history.push(`/results/${id}`);
            }}
            sx={{ mr: 2 }}
            color="inherit"
            aria-label={t("misc.back")}
          >
            <ArrowBack />
          </IconButton>

          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {t("edit.title", {
              property: property.propertyName,
            })}
          </Typography>

          <IconButton
            onClick={() => setMissingDialogOpen(true)}
            disabled={disableMissingButton}
            color="inherit"
            aria-label={t("edit.missing")}
          >
            <BallotIcon />
          </IconButton>
          <IconButton
            onClick={() => setCloneDialogOpen(true)}
            color="inherit"
            aria-label={t("edit.clone")}
          >
            <FileCopy />
          </IconButton>
          <IconButton
            onClick={handleSave}
            disabled={isDisabled}
            color="inherit"
            aria-label={t("edit.save")}
          >
            <Save />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box sx={{ m: 2 }}>
        <Paper sx={{ mb: 3 }}>
          <Form
            onSubmit={save}
            mutators={{
              ...arrayMutators,
            }}
            initialValues={property}
            render={({ handleSubmit, values }: any) => {
              submit = handleSubmit;

              return (
                <form
                  onSubmit={handleSubmit}
                  style={{ padding: 10 }}
                  id="wikiForm"
                >
                  <AppBar position="static">
                    <Tabs
                      variant="scrollable"
                      indicatorColor="primary"
                      textColor="inherit"
                      scrollButtons={true}
                      value={activeStep}
                      onChange={changeTab}
                      aria-label="simple tabs example"
                    >
                      {property.types.map(
                        (
                          section: { typeId: string; typeName: string },
                          index: number
                        ) => {
                          return (
                            <Tab
                              key={section.typeId}
                              label={section.typeName}
                              {...a11yProps(index)}
                            />
                          );
                        }
                      )}
                    </Tabs>
                  </AppBar>

                  <FieldArray name="types">
                    {({ fields }) =>
                      fields.map((type, parentIndex) => (
                        <TabPanel
                          value={activeStep}
                          key={parentIndex}
                          index={parentIndex}
                        >
                          <FieldArray name={`${type}.items`}>
                            {({ fields }) => (
                              <>
                                {fields.map((item: any, index: any) => (
                                  <div key={`itp${index}`}>
                                    <Field
                                      name={`${item}.itemName`}
                                      component={({ input }) => {
                                        return (
                                          <Typography variant="h6">
                                            {input.value}
                                          </Typography>
                                        );
                                      }}
                                    />

                                    <Radio
                                      label={t("edit.itemExists")}
                                      name={`${item}.itemExists`}
                                      options={[
                                        {
                                          label: t("edit.noAnswer"),
                                          value: "0",
                                        },
                                        { label: t("edit.yes"), value: "1" },
                                        { label: t("edit.no"), value: "2" },
                                      ]}
                                    />
                                    {values.types[parentIndex].items[index]
                                      .itemExists === "1" && (
                                      <FieldArray name={`${item}.subItems`}>
                                        {({ fields }) => (
                                          <Box
                                            key={`it${item.itemId}`}
                                            sx={{ marginBottom: 2 }}
                                          >
                                            {fields.map(
                                              (items: any, index: any) => (
                                                <Box
                                                  key={index}
                                                  sx={{ marginBottom: 2 }}
                                                >
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent:
                                                        "space-between",
                                                      borderBottom: (theme) =>
                                                        `solid 2px ${theme.palette.primary.main}`,
                                                      marginBottom: 2,
                                                    }}
                                                  >
                                                    <Typography variant="body1">
                                                      #{parseInt(index + 1)}
                                                    </Typography>

                                                    <IconButton
                                                      onClick={() =>
                                                        confirmDelete(
                                                          fields,
                                                          index
                                                        )
                                                      }
                                                    >
                                                      <DeleteIcon />
                                                    </IconButton>
                                                  </Box>

                                                  <Box sx={{ my: 2 }}>
                                                    <InputLabel>
                                                      {t("edit.location")}
                                                    </InputLabel>

                                                    <Select
                                                      name={`${items}.locationId`}
                                                      options={rooms.map(
                                                        (option: any) => ({
                                                          value:
                                                            option.sectionId,
                                                          label:
                                                            option.sectionName,
                                                        })
                                                      )}
                                                    />
                                                  </Box>

                                                  <Box sx={{ my: 2 }}>
                                                    <InputLabel>
                                                      {t("edit.images")}
                                                    </InputLabel>

                                                    <Uploader
                                                      name={`${items}.itemImages`}
                                                      destination="wiki"
                                                      uploadType="wiki"
                                                    />
                                                  </Box>

                                                  <Box sx={{ my: 2 }}>
                                                    <InputLabel>
                                                      {t("edit.comments")}
                                                    </InputLabel>

                                                    <TextField
                                                      name={`${items}.itemComments`}
                                                      multiline
                                                      fullWidth
                                                    />
                                                  </Box>
                                                </Box>
                                              )
                                            )}
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              onClick={() =>
                                                fields.push({
                                                  locationId:
                                                    values.types[parentIndex]
                                                      .items[index].locationId,
                                                  itemImages: [],
                                                  itemComments: "",
                                                })
                                              }
                                            >
                                              {t("edit.addItem")}
                                            </Button>
                                          </Box>
                                        )}
                                      </FieldArray>
                                    )}
                                  </div>
                                ))}
                              </>
                            )}
                          </FieldArray>
                        </TabPanel>
                      ))
                    }
                  </FieldArray>
                </form>
              );
            }}
          />
        </Paper>
      </Box>
      <MissingDialog
        setDisableMissingButton={setDisableMissingButton}
        open={missingDialogOpen}
        onClose={() => setMissingDialogOpen(false)}
      />
      <CloneDialog
        propertyId={id}
        open={cloneDialogOpen}
        onClose={() => setCloneDialogOpen(false)}
        onClone={onClone}
      />
    </>
  );
};

export default Edit;
