import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const configuration = {
  appName: "Wiki",
  appIcon: <FontAwesomeIcon icon={["fab", "weebly"]} />,
  primary: "#4E5E9E",
  secondary: "#53C3E6",
};

export default configuration;
