import React, { useContext, useState, useEffect } from "react";

import { Loader } from "@stayunique/stayunique-ui";

//utils
import fetcher from "../utils/fetcher";

const AuthContext = React.createContext<any>(undefined);

const AuthProvider = ({ children }: any) => {
  const [auth, setAuth] = useState<object | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const logout = async (user: any) => {
    try {
      await fetcher("logout", {
        method: "POST",
        body: JSON.stringify(user),
      });

      setIsAuthenticated(false);
      setAuth(null);
    } catch (error) {
      throw error;
    }
  };

  const login = async (user: any) => {
    try {
      const result = await fetcher("login", {
        method: "POST",
        body: JSON.stringify(user),
      });

      setAuth(result.data);
      setIsAuthenticated(true);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    setIsLoading(true);
    async function checkLogin() {
      try {
        const result = await fetcher("me");
        setAuth(result.data);
        setIsAuthenticated(true);
      } catch (e) {
        //login error
      }

      setIsLoading(false);
    }

    checkLogin();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        logout,
        login,
        isAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const auth = useContext(AuthContext);
  if (auth == null) {
    throw new Error("You must call useAuth only when inside a AuthProvider.");
  }

  return auth;
}

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthContext, AuthConsumer, useAuth };
