import React from "react";

import { useConfig } from "../../context/ConfigContext";

const Logo: React.FC = () => {
  const {
    config: { appIcon },
  } = useConfig();

  return React.cloneElement(appIcon, { style: { marginRight: 8 } });
};

export default Logo;
